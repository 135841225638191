<template>
  <div>
    <div class="title flexB" style="display: block;margin-top:0;">
      <h1 v-if="editMode"> {{ $t("user-detail-title")}}</h1>
      <h1 v-else> {{ $t("user-register-title")}}</h1>
<!--      <div>
        &lt;!&ndash;        <span
        >{{ moment().format("YYYY.MM.DD(dd) HH:mm") }} {{ $t("data-today") }}</span
        >&ndash;&gt;
        <button class="medium" @click="handleRefresh">{{ $t("btn-refresh") }}</button>
      </div>-->
    </div>
    <div class="contents userRt">
      <div class="box one">
        <div v-if="editMode">
          <div class="flex">
            <p class="bold">{{ $t("user-data-num") }}</p>
            <p>{{ userId }}</p>
          </div>
          <div class="flex">
            <p class="bold">{{ $t("user-data-regdate") }}</p>
            <p>
              {{ moment(registerDate).format("YYYY.MM.DD HH:mm") }} {{ registerUserName ? '/ ' + registerUserName : "" }}
            </p>
          </div>
        </div>
        <div v-else>
          <div class="flex full">
            <p class="bold">{{ $t("user-data-email-id") }}<span>*</span></p>
            <input type="email" v-model="loginId" pattern="[^ @]*@[^ @]*"/>
            <button class="small margin6" @click="checkLoginId">
              {{ $t("btn-duplicate")}}
            </button>
            <span :class="{ blue: check, red: !check }">{{ checkText }}</span>
          </div>
          <div class="flex full">
            <p class="bold">{{ $t("user-data-pw") }}</p>
            <span class="gray" style="font-size: 1.2rem;">{{ $t("user-data-info-pw-setting") }}</span>
          </div>
        </div>
        <div v-if="editMode" class="flex">
          <p class="bold">{{ $t("user-data-email-id") }}<span>*</span></p>
          <p v-if="editMode">{{ loginId }}</p>
          <input v-else type="text" v-model="loginId" />
        </div>
        <div v-if="editMode" class="flex">
          <p class="bold">{{ $t("user-data-pw") }}</p>
          <div class="flexB">
            <input type="password" v-model="password" />
            <button class="small margin6" @click="resetPassword">
              {{ $t("btn-pw-reset") }}
            </button>
          </div>
        </div>
        <!-- <div v-if="editMode" class="flex">
          <p class="bold">회원가입 채널</p>
          <p>컨트롤러</p>
        </div> -->
        <div></div>
        <div class="flex">
          <p class="bold">{{ $t("user-data-name") }}<span>*</span></p>
          <input type="text" v-model="name" />
        </div>
        <div class="flex">
          <p class="bold">{{ $t("user-data-phone-num") }}<span>*</span></p>
          <input
              type="text"
              :value="phone"
              @input="bindNumber"
          />
        </div>
        <div class="flex">
          <p class="bold">{{ $t("user-data-dept-name") }}<span>*</span></p>
          <select v-model="departmentId">
            <option disabled value="">{{ $t("select-box-default-choose") }}</option>
            <option
                v-for="(data, i) in departmentList"
                :key="i"
                :value="data.departmentId"
            >
              {{ data.name }}
            </option>
          </select>
        </div>
        <div class="flex">
          <p class="bold">{{ $t("user-data-work") }}</p>
          <input type="text" v-model="ownerWork" />
        </div>
        <div class="flex">
          <p class="bold">{{ $t("user-data-status") }}<span>*</span></p>
          <select class="small" v-model="status">
            <option value="Waiting">{{ $t("user-data-status-waiting") }}</option>
            <option value="Used">{{ $t("user-data-status-used") }}</option>
            <option value="NotUsed" v-if="isAdmin">{{ $t("user-data-status-not-used") }}</option>
            <option value="Inactive" v-if="isAdmin">{{ $t("user-data-status-inactivate") }}</option>
            <option value="Unsubscribe" v-if="isAdmin">{{ $t("user-data-status-withdrawal") }}</option>
          </select>
        </div>

        <div class="flex">
          <p class="bold">{{ $t("user-data-app-available") }}</p>
          <label style="font-size: 1.4rem;">
            <input
                type="checkbox"
                v-model="isControlAppAuth"
            />{{ $t("user-data-app-controller") }}
          </label>
          <label style="font-size: 1.4rem; margin-left: 20px;">
            <input
                type="checkbox"
                v-model="isViewerAppAuth"
            />{{ $t("user-data-app-viewer") }}
          </label>
          <label style="font-size: 1.4rem; margin-left: 20px;">
            <input
                type="checkbox"
                v-model="isStreamerAppAuth"
            />{{ $t("user-data-app-streamer") }}
          </label>
        </div>

        <div class="flex">
          <p class="bold">{{ $t("user-data-authority") }}<span>*</span></p>
          <select class="small" v-model="userType">
            <option value="User">{{ $t("user-data-type-user") }}</option>
            <!-- <option value="Manager">현장담당자</option> -->
            <option value="Admin" v-if="isAdmin">{{ $t("user-data-type-admin") }}</option>
          </select>
        </div>

        <div class="buttonWrap">
          <button v-if="editMode" class="large left" @click="handleDelete">
            {{ $t("btn-delete") }}
          </button>
          <div>
            <button class="point large" @click="submit">{{ $t("btn-confirm") }}</button>
            <button class="large" @click="handleCancel">{{ $t("btn-cancel") }}</button>
          </div>
        </div>
      </div>
      <div class="box one flexB infomation">
        <div>
          <p class="bold"><i class="fas fa-info-circle"></i> {{ $t("user-data-status-select-title") }}</p>
          <table>
            <tr>
              <th>{{ $t("user-data-status-select-name") }}</th>
              <th>{{ $t("user-data-status-select-explanation") }}</th>
            </tr>
            <tr>
              <td>{{ $t("user-data-status-waiting") }}</td>
              <td>
                {{ $t("user-data-status-waiting-explanation") }}
              </td>
            </tr>
            <tr>
              <td>{{ $t("user-data-status-used") }}</td>
              <td>
                {{ $t("user-data-status-used-explanation") }}
              </td>
            </tr>
            <tr>
              <td>{{ $t("user-data-status-not-used") }}</td>
              <td>
                {{ $t("user-data-status-not-used-explanation") }}
              </td>
            </tr>
            <tr>
              <td>{{ $t("user-data-status-inactivate") }}</td>
              <td>
                {{ $t("user-data-status-inactivate-explanation") }}
              </td>
            </tr>
            <tr>
              <td>{{ $t("user-data-status-withdrawal") }}</td>
              <td>
                {{ $t("user-data-status-withdrawal-explanation") }}
              </td>
            </tr>
          </table>
        </div>
        <div>
          <p class="bold"><i class="fas fa-info-circle"></i> {{ $t("user-data-authority-select-title") }}</p>
          <table>
            <tr>
              <th>{{ $t("user-data-authority-select-name") }}</th>
              <th>{{ $t("user-data-status-select-explanation") }}</th>
            </tr>
            <tr>
              <td>{{ $t("user-data-type-admin") }}</td>
              <td>{{ $t("user-data-authority-admin-explanation") }}</td>
            </tr>
            <tr>
              <td>{{ $t("user-data-type-user") }}</td>
              <td>{{ $t("user-data-authority-user-explanation") }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
input[type="checkbox"] {
  width: 16px;
  height: 16px;
  margin: 0 8px 0 0;
}
</style>

<script>
// import sha256 from "js-sha256";
import { format } from "@/mixins/format";
import moment from "moment";
import { mapState } from "vuex";
import {
  checkId,
} from "@/api/login";
import {
  fetchDepartmentList,
} from "@/api/department";
import {
  createUser,
  updateUser,
  fetchUserDetails,
  deleteUser,
} from "@/api/user";
export default {
  name: "UserRegister",
  mixins: [format],
  data() {
    return {
      moment: moment,
      editMode: false,
      status: "Used",
      userType: "User",
      registerDate: null,
      registerUserName : "",
      ownerWork: "",
      phone: "",
      name: "",
      loginId: "",
      password: "",
      passwordConfirm: "",
      departmentId: "",
      departmentList: [],
      error: false,
      userId: "",
      check: "",
      checkText: "",
      verify: false,
      registerUserId : null,
      loginUserType:null,
      isViewerAppAuth: null,
      isControlAppAuth: null,
      isStreamerAppAuth: null,

    };
  },
  mounted() {
    this.registerUserId = localStorage.getItem("userId");
    this.getDepartmentList();
    if (this.$route.query.userId) {
      this.editMode = true;
      this.getUserDetails();
    }
  },
  watch : {
    phone(val){
      let check = /^[0-9]+$/;
      if (!check.test(val)) {
        this.phone = val.replace(/[^0-9]/g,'');
      }
    },
  },
  computed: {
    ...mapState({
      isAdmin: state => state.store.isAdmin,
      accountId: state => state.store.accountId,
    }),
  },
  methods: {
    checkLoginId() {

      // check for id format
      const validateEmail = (email) => {
        return email.match(
            "^\\S+@\\S+$"
        );
      };


      if(validateEmail(this.loginId)){
        this.verify = true;
        this.checkText = "";

      }else {
        this.verify = false;
        this.checkText = " e-mail 형식이 아닙니다.";

        return;
      }

      let data = {
        accountId: this.accountId,
      };

      checkId(this.loginId, data).then((res) => {
        this.check = res.data.data;
        if (this.check) {
          this.verify = true;
          this.checkText = "등록이 가능한 아이디입니다.";
        } else {
          this.verify = false;
          this.checkText = " 이미 가입한 아이디입니다.";
        }
      });
    },
    getDepartmentList() {
      var params = {
        statuses : "Used"
      }
      fetchDepartmentList(params).then((res) => {
        this.departmentList = res.data.data.content;
      });
    },
    bindNumber(event){
      this.phone = event.target.value;
    },
    getUserDetails() {
      let userId = this.$route.query.userId;
      fetchUserDetails(userId).then((res) => {
        if (res.data.result == 0) {
          this.departmentId = res.data.data.departmentId;
          this.userId = res.data.data.userId;
          this.loginId = res.data.data.loginId;
          this.name = res.data.data.name;
          this.phone = res.data.data.phone;
          this.ownerWork = res.data.data.ownerWork;
          this.registerDate = res.data.data.registerDate;
          this.registerUserName = res.data.data.registerUserName
          this.userType = res.data.data.userType;
          this.status = res.data.data.status;
          this.isControlAppAuth = res.data.data.isControlAppAuth;
          this.isViewerAppAuth = res.data.data.isViewerAppAuth;
          this.isStreamerAppAuth = res.data.data.isStreamerAppAuth
          //loginDate,modDate
        } else {
          alert(res.message);
        }

      });
    },
    handleCancel() {
      this.$router.go(-1);
    },
    handleDelete() {
      let result = confirm("사용자 정보를 삭제하시겠습니까?");
      if (result == false) {
        return;
      }
      let userId = this.$route.query.userId;
      deleteUser(userId).then((res) => {
        if (res.data.result == 0) {
          alert(this.$t("alert-message-delete"));
          this.$router.push("userList");
        } else {
          alert(res.data.message);
        }
      });
    },
    handleRefresh() {
      this.$router.go();
    },
    resetPassword() {
      this.password = this.loginId.substr(0, 4) + this.phone.substr(-4) + "!";
      alert("사용자의 이메일 앞 4자리 + 연락처 뒷 4자리 + '!' 로 비밀번호가 초기화되었습니다..");
      // let data = {
      //   loginId: this.loginId,
      //   name: this.name,
      //   phone: this.phone,
      // };
      // findPw(data).then((res) => {
      //   if (res.data.result == 0) {
      //     alert("사용자의 이메일로 비밀번호 재설정url이 전송하였습니다.");
      //   } else {
      //     let message = res.data.message;
      //     alert(message);
      //   }
      // });
    },
    submit() {
      let idLen = this.loginId.split("@")[0].length;
      let phoneLen = this.phone.split("").length;
      if (!this.editMode && !this.verify) {
        return alert(this.$t("alert-message-check-double-id"));
      } else if (idLen < 1) {
        return alert(this.$t("alert-message-check-id-characters"));
      } else if (this.name == "") {
        return alert(this.$t("alert-message-check-name"));
      } else if (this.phone == "") {
        return alert(this.$t("alert-message-check-phone-num"));
      } else if (phoneLen < 4) {
        return alert(this.$t("alert-message-check-phone-characters"));
      } else if (this.departmentId == "") {
        return alert(this.$t("alert-message-check-dept"));
      }

      if (!this.checkEmailFormat(this.loginId)) {
        return alert(this.$t("alert-message-check-email-format"));
      }
      // else if (this.password != this.passwordConfirm) {
      //   return alert("비밀번호가 다릅니다.");
      // }
      if (this.editMode) {
        let data = {
          accountId: this.accountId,
          loginId: this.loginId,
          password: this.password,
          name: this.name,
          phone: this.phone,
          ownerWork: this.ownerWork,
          status: this.status,
          userType: this.userType,
          departmentId: this.departmentId,
          isViewerAppAuth: this.isViewerAppAuth,
          isControlAppAuth: this.isControlAppAuth,
          isStreamerAppAuth : this.isStreamerAppAuth,
        };
        if (!data.password) {
          delete data.password;
        }
        let userId = this.$route.query.userId;

        updateUser(userId, data).then((res) => {
          if (res.data.result == 0) {
            alert(this.$t("alert-message-check-user-info-upload"));
            this.$router.go(-1);
          } else if(res.data.result === -2001){
            alert(this.$t("alert-message-app-usage-limit-exceeded"));
          }
          else {
            let message = res.data.message;
            alert(message);
          }
        });
      } else {
        let data = {
          accountId: this.accountId,
          name: this.name,
          loginId: this.loginId,
          password: this.loginId.substr(0, 4) + this.phone.substr(-4) + "!",
          phone: this.phone,
          ownerWork: this.ownerWork,
          departmentId: this.departmentId,
          status: this.status,
          registerUserId : this.registerUserId,
          isViewerAppAuth: this.isViewerAppAuth,
          isControlAppAuth: this.isControlAppAuth,

        };
        createUser(data).then((res) => {
          if (res.data.result == 0) {
            alert(this.$t("alert-message-check-user-info-create"));
            this.$router.go(-1);
          }else if(res.data.result === -2001){
            alert(this.$t("alert-message-app-usage-limit-exceeded"));
          }else {
            let message = res.data.message;
            alert(message);
          }
        });
      }
    },
  },
};
</script>