export const format = {
  methods: {
    // emailValidate(email) {
    //   let exptext = /^[A-Za-z0-9_\.\-]+@[A-Za-z0-9\-]+\.[A-Za-z0-9\-]+/;

    //   if (exptext.test(email) == false) {
    //     //이메일 형식이 알파벳+숫자@알파벳+숫자.알파벳+숫자 형식이 아닐경우

    //     alert("이메일형식이 올바르지 않습니다.");

    //     // document.addjoin.email.focus();

    //     return false;
    //   }
    // },
    checkEmailFormat(email) {
      let emailRule =
        /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i; //이메일 정규식

      if (!emailRule.test(email)) {
        return false;
      } else {
        return true;
      }
    },
    checkMobileFormat(num) {
      let reg = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
      if (!reg.test(num)) {
        return false;
      } else {
        return true;
      }
    },

    //비밀번호 영문,숫자,특수기호 2개 이상 조합하여 8자리 이상
    checkPasswordFormat(pw) {
      let num = pw.search(/[0-9]/g);
      let eng = pw.search(/[a-z]/gi);
      let spe = pw.search(/[`~!@@#$%^&*|₩₩₩'₩";:₩/?]/gi);
      let len = pw.split("").length;

      if (
        (num < 0 && eng < 0) ||
        (eng < 0 && spe < 0) ||
        (spe < 0 && num < 0) ||
        len < 8
      ) {
        return false;
      } else {
        return true;
      }
    },
    //날짜 검색 (시작 < 종료) 확인
    dateRange(start, end) {
      let strDate = new Date(start);
      let endDate = new Date(end);
      if (strDate > endDate) {
        return false;
      } else {
        return true;
      }
    },
    convertDateFormat(date) {
      if (!date) return "";
      let year = date.slice(0, 4);
      let month = date.slice(4, 6);
      let day = date.slice(6, 8);
      let hour = date.slice(8, 10);
      let minutes = date.slice(10, 12);
      let seconds = date.slice(12, 14);
      // let obj = year + "-" + month + "-" + day + " " + hour + ":" + minutes + ":" + seconds;
      let obj = `${year}-${month}-${day}${
        hour ? " " + hour + ":" + minutes + ":" + seconds : ""
      }`;
      return `${obj}`;
    },
    convertMobileFormat(mobilNo) {
      let head = mobilNo.slice(0, 3);
      let middle = mobilNo.slice(3, 7);
      let end = mobilNo.slice(7, 11);
      let obj = head + "-" + middle + "-" + end;
      return `${obj}`;
    },
    HourMinuteFormat(value) {
      let hour = value.slice(0, 2);
      let minute = value.slice(2, 4);
      let obj = hour + ":" + minute;
      return `${obj}`;
    },
    numberFormat(inputNumber) {
      if (inputNumber === 0) return "0";
      if (!inputNumber) return "";
      return inputNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    setNavbar(visible, category) {
      let data = {
        visible: visible,
        category: category,
      };
      this.$store.dispatch("store/SET_NAVBAR", data);
    },
    comma(str) {
      str = String(str);
      return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,");
    },
    uncomma(str) {
      str = String(str);
      return str.replace(/[^\d]+/g, "");
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    // getPhoneMask(val) {
    //   let res = this.getMask(val);
    //   this.contact = res;
    // },
    getMask(phoneNumber) {
      if (!phoneNumber) return phoneNumber;
      phoneNumber = phoneNumber.replace(/[^0-9]/g, "");

      let res = "";
      if (phoneNumber.length < 3) {
        res = phoneNumber;
      } else {
        if (phoneNumber.substr(0, 2) == "02") {
          if (phoneNumber.length <= 5) {
            //02-123-5678
            res = phoneNumber.substr(0, 2) + "-" + phoneNumber.substr(2, 3);
          } else if (phoneNumber.length > 5 && phoneNumber.length <= 9) {
            //02-123-5678
            res =
              phoneNumber.substr(0, 2) +
              "-" +
              phoneNumber.substr(2, 3) +
              "-" +
              phoneNumber.substr(5);
          } else if (phoneNumber.length > 9) {
            //02-1234-5678
            res =
              phoneNumber.substr(0, 2) +
              "-" +
              phoneNumber.substr(2, 4) +
              "-" +
              phoneNumber.substr(6);
          }
        } else {
          if (phoneNumber.length < 8) {
            res = phoneNumber;
          } else if (phoneNumber.length == 8) {
            res = phoneNumber.substr(0, 4) + "-" + phoneNumber.substr(4);
          } else if (phoneNumber.length == 9) {
            res =
              phoneNumber.substr(0, 3) +
              "-" +
              phoneNumber.substr(3, 3) +
              "-" +
              phoneNumber.substr(6);
          } else if (phoneNumber.length == 10) {
            res =
              phoneNumber.substr(0, 3) +
              "-" +
              phoneNumber.substr(3, 3) +
              "-" +
              phoneNumber.substr(6);
          } else if (phoneNumber.length > 10) {
            //010-1234-5678
            res =
              phoneNumber.substr(0, 3) +
              "-" +
              phoneNumber.substr(3, 4) +
              "-" +
              phoneNumber.substr(7);
          }
        }
      }
      return res;
    },
    getBirthday(birthday) {
      if (!birthday) return birthday;
      birthday = birthday.replace(/[^0-9]/g, "");
      let res = "";
      if (birthday.length < 8) {
        res = birthday;
      } else {
        if (birthday.length == 8) {
          res = birthday.substr(0, 8) + "-" + birthday.substr(8);
        }
        // else if (birthday.length > 8 && birthday.lengh < 11) {
        //   res = birthday.substr(0, 8) + "-" + birthday.substr(8, 9);
        // }
        else if (birthday.length == 9) {
          res = birthday.substr(0, 8) + "-" + birthday.substr(8);
        } else {
          let temp = birthday.substr(0, 9);
          res = temp.substr(0, 8) + "-" + temp.substr(8);
        }
      }
      return res;
    },
  },
  // watch: {
  //   price(newValue) {
  //     const result = newValue.replace(/\D/g, "")
  //       .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  //     Vue.nextTick(() => this.price = result);
  //   }
  // };
};
