import axios from "axios";
import { baseUrl, baseApiVersion, headers } from "../config/env";

//ok
export function login(data) {
  return axios.post(baseUrl + "/api/v1/sign-in", data, { headers });
}

export function logout(data) {
  return axios.post(baseUrl + "/api/v1/sign-in", data, { headers });
}

export function findId(data) {
  return axios.post(baseUrl + "/api/v1/findId", data);
}

export function findPw(data) {
  return axios.post(baseUrl + "/api/v1/findPw", data);
}

export function checkId(id, params) {
  return axios.get(baseUrl + baseApiVersion + `/user/checkId/${id}`, { params, headers });
}

export function guestLogin(data) {
  return axios.post(baseUrl + baseApiVersion + `/guest/sign-in`, data, { headers });
}

export function changePwd(accountId, sessionKey, data) {
  return axios.patch(
      baseUrl + baseApiVersion + `/${accountId}/pwReset/${sessionKey}`,
      data,
      { headers }
  );
}

export function checkSessionKey(accountId, sessionKey) {
  localStorage.removeItem("token");
  return axios.post(
      baseUrl + baseApiVersion + `/${accountId}/checkKey/${sessionKey}`,
      { headers }
  );
}

export function changeStatus() {
  return axios.patch(baseUrl + baseApiVersion + `/user/changeStatus`, null, { headers });
}
